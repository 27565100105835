import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {TicketsService} from 'src/app/modules/membership/tickets.service';
import {
    ActivateTicketAutomaticRenewal,
    DeactivateTicketAutomaticRenewal,
    GetNextTicketInOrder,
    GetPrevTicketInOrder,
    GetTicket,
    SetTicketsOfCurrentOrder,
} from './tickets-item.actions';
import {Navigate} from '@ngxs/router-plugin';
import {firstValueFrom} from 'rxjs';
import {errorHandler, httpErrorToString} from 'src/app/shared/helpers/error-handler';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {ITicket} from 'src/app/interfaces/general/profile-definitions/ticket.interface';
import {MarketplaceApiService} from 'src/app/shared/services/marketplace-api.service';

export interface ITicketsOfCurrentOrder {
    orderNumber: string;
    ticketsIds: string[];
}

export interface ITicketItemState {
    ticket: ITicket;
    loading: boolean;
    hasValue: boolean;
    error: any;
    ticketsOfCurrentOrder: ITicketsOfCurrentOrder;
}

@State<ITicketItemState>({
    name: 'ticketItem',
    defaults: {
        ticket: null,
        loading: false,
        hasValue: false,
        error: null,
        ticketsOfCurrentOrder: null,
    },
})
@Injectable()
export class TicketsItemState {
    constructor(
        private ticketsService: TicketsService,
        private marketplaceApiService: MarketplaceApiService,
        private store: Store,
        private insights: AppInsightsService
    ) {}

    @Selector()
    public static ticket(state: ITicketItemState): ITicket {
        return state.ticket;
    }

    @Selector()
    public static ticketsOfCurrentOrder(state: ITicketItemState): ITicketsOfCurrentOrder {
        return state.ticketsOfCurrentOrder;
    }

    @Selector()
    public static loading(state: ITicketItemState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: ITicketItemState): boolean {
        return state.hasValue;
    }

    @Selector()
    public static error(state: ITicketItemState): any {
        return state.error;
    }

    public static hasPrevValueInOrder(id: string) {
        return createSelector([TicketsItemState], (state: ITicketItemState) => {
            return (
                state.ticketsOfCurrentOrder?.ticketsIds?.findIndex((ticketId) => ticketId === id) >
                0
            );
        });
    }

    public static hasNextValueInOrder(id: string) {
        return createSelector([TicketsItemState], (state: ITicketItemState) => {
            return (
                state.ticketsOfCurrentOrder?.ticketsIds?.findIndex((ticketId) => ticketId === id) <
                state.ticketsOfCurrentOrder?.ticketsIds?.length - 1
            );
        });
    }

    @Action(GetPrevTicketInOrder)
    public getPrevTicketInOrder(
        ctx: StateContext<ITicketItemState>,
        {id}: GetPrevTicketInOrder
    ): void {
        const state = ctx.getState();
        const indexOfPrev =
            state.ticketsOfCurrentOrder.ticketsIds.findIndex((ticketId) => ticketId === id) - 1;
        this.store.dispatch(
            new Navigate([
                '/orders',
                state.ticketsOfCurrentOrder.orderNumber,
                'tickets',
                state.ticketsOfCurrentOrder.ticketsIds[indexOfPrev],
            ])
        );
    }

    @Action(GetNextTicketInOrder)
    public getNextTicketInOrder(
        ctx: StateContext<ITicketItemState>,
        {id}: GetNextTicketInOrder
    ): void {
        const state = ctx.getState();
        const indexOfNext =
            state.ticketsOfCurrentOrder.ticketsIds.findIndex((ticketId) => ticketId === id) + 1;

        this.store.dispatch(
            new Navigate([
                '/orders',
                state.ticketsOfCurrentOrder.orderNumber,
                'tickets',
                state.ticketsOfCurrentOrder.ticketsIds[indexOfNext],
            ])
        );
    }

    @Action(GetTicket)
    public async getTicket(ctx: StateContext<ITicketItemState>, {id, settings}: GetTicket): Promise<any> {
        try {
            const silent = settings?.silent ?? false;
            ctx.patchState({error: null});
            if (!silent) {
                ctx.patchState({loading: true});
            }
            const ticket: ITicket = await firstValueFrom(this.ticketsService.getTicketById(id));
            ctx.patchState({
                ticket,
                hasValue: !!ticket,
                loading: false,
                error: null,
            });
        } catch (error) {
            errorHandler({
                error,
                appInsightsSrv: this.insights,
                scope: 'TicketsItemState',
            });
            ctx.patchState({
                error: httpErrorToString(error),
                ticket: null,
                hasValue: false,
                loading: false,
            });
        }
    }

    @Action(ActivateTicketAutomaticRenewal)
    public async activateTicketAutomaticRenewal(
        ctx: StateContext<ITicketItemState>,
        {id, settings}: ActivateTicketAutomaticRenewal
    ): Promise<ITicket> {
        const omitErrorInState = settings?.omitErrorInState ?? false;
        try {
            ctx.patchState({error: null});
            const ticket = await firstValueFrom(this.marketplaceApiService.activateAutomaticRenewal(id));
            ctx.patchState({
                ticket,
                hasValue: !!ticket,
                loading: false,
                error: null,
            });
            return ticket;
        } catch (error) {
            errorHandler({
                error,
                appInsightsSrv: this.insights,
                scope: 'TicketsItemState',
            });
            if (!omitErrorInState) {
                ctx.patchState({
                    error: httpErrorToString(error),
                    loading: false,
                });
            }
            ctx.patchState({
                loading: false,
            });
            throw error;
        }
    }

    @Action(DeactivateTicketAutomaticRenewal)
    public async deactivateTicketAutomaticRenewal(
        ctx: StateContext<ITicketItemState>,
        {id, settings}: ActivateTicketAutomaticRenewal
    ): Promise<ITicket> {
        const omitErrorInState = settings?.omitErrorInState ?? false;
        try {
            ctx.patchState({error: null});
            const ticket = await firstValueFrom(this.marketplaceApiService.deactivateAutomaticRenewal(id));
            ctx.patchState({
                ticket,
                hasValue: !!ticket,
                loading: false,
                error: null,
            });
            return ticket;
        } catch (error) {
            errorHandler({
                error,
                appInsightsSrv: this.insights,
                scope: 'TicketsItemState',
            });
            if (!omitErrorInState) {
                ctx.patchState({
                    error: httpErrorToString(error),
                    loading: false,
                });
            }
            ctx.patchState({
                loading: false,
            });
            throw error;
        }
    }

    @Action(SetTicketsOfCurrentOrder)
    public setTicketsOfCurrentOrder(
        ctx: StateContext<ITicketItemState>,
        {order}: SetTicketsOfCurrentOrder
    ) {
        if (!order) {
            ctx.patchState({ticketsOfCurrentOrder: null});
            return;
        }
        if (order.orderNumber === ctx.getState().ticketsOfCurrentOrder?.orderNumber) {
            return;
        }

        let allTicketsOfOrder: any[] = [];
        order.orderedItem?.forEach((item) => {
            allTicketsOfOrder = allTicketsOfOrder.concat(item.ticket);
        });
        allTicketsOfOrder = allTicketsOfOrder.map((ticket) => ticket.identifier);

        ctx.patchState({
            ticketsOfCurrentOrder: {
                orderNumber: order.orderNumber,
                ticketsIds: allTicketsOfOrder,
            },
        });
    }
}
