import {IOrder} from "src/app/interfaces/general/profile-definitions/order.interface";


export class GetTicket {
    public static readonly type = '[TicketItem] Get ticket';
    constructor(public id: string, public settings?: {silent: boolean}) {}
}

export class ActivateTicketAutomaticRenewal {
    public static readonly type = '[TicketItem] Activate ticket (membership only) automatic renewal';
    constructor(public id: string, public settings?: {omitErrorInState: boolean}) {}
}

export class DeactivateTicketAutomaticRenewal {
    public static readonly type = '[TicketItem] Deactivate ticket (membership only) automatic renewal';
    constructor(public id: string, public settings?: {omitErrorInState: boolean}) {}
}

export class SetTicketsOfCurrentOrder {
    public static readonly type = '[TicketItem] Set tickets of current order';
    constructor(public order: IOrder) {}
}

export class GetPrevTicketInOrder {
    public static readonly type = '[TicketItem] Get prev ticket in order';
    constructor(public id: string) {}
}

export class GetNextTicketInOrder {
    public static readonly type = '[TicketItem] Get next ticket in order';
    constructor(public id: string) {}
}
